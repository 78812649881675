<template>
  <v-row>
    <v-col>
      <date-range-picker
        v-model="filter.date"
        @input="update"
      />
    </v-col>

    <v-col>
      <v-select
        v-model="filter.position"
        label="Должность"
        :items="positions"
        @change="update"
      />
    </v-col>

    <v-col>
      <v-select
        v-model="filter.structure"
        label="Подразделение"
        :items="structures"
        @change="update"
      />
    </v-col>

    <v-col>
      <v-select
        v-model="filter.sex"
        label="Пол"
        :items="sexOptions"
        @change="update"
      />
    </v-col>

    <v-col>
      <v-select
        v-model="filter.age"
        label="Возраст"
        :items="ageOptions"
        @change="update"
      />
    </v-col>

    <v-col v-if="showGroupingSelect">
      <v-select
        label="Группировка"
        @change="update"
      />
    </v-col>
  </v-row>
</template>

<script>
import DateRangePicker from '@/views/components/forms/DateRangePicker.vue';
import { mapState } from 'vuex';

export default {
  name: 'ReportFilter',

  components: { DateRangePicker },

  props: {
    showGroupingSelect: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    filter: {
      date: null,
      position: null,
      structure: null,
      sex: null,
      age: null,
    },
  }),

  computed: {
    ...mapState('reportFilter', ['ageOptions', 'positions', 'structures', 'sexOptions']),
  },

  created() {
    this.filter = { ...this.filter, ...this.value };
  },

  methods: {
    update() {
      const filter = {};

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          filter[key] = this.filter[key];
        }
      });

      this.$emit('input', filter);
    },
  },
};
</script>

<style scoped>

</style>
