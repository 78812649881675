import Vue from 'vue';
import Vuex from 'vuex';
import { NotificationStoreModule } from '@/plugins/notifications/notification-store-module';
import userModule from './user.module';
import reportFilterModule from './report-filter.module';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: userModule,
    reportFilter: reportFilterModule,

    // plugins
    [NotificationStoreModule.name]: NotificationStoreModule,
  },
});

export default store;
