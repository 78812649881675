import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import toNumber from 'lodash/toNumber';

export default class LineSeries {
  constructor(id, data, value, category, maxWidth) {
    this.id = id;
    this.data = data;
    this.value = value;
    this.category = category;
    this.maxWidth = maxWidth;
    this.chart = null;
  }

  start() {
    this.chart = am4core.create(this.id, am4charts.XYChart);

    // Create axes
    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;

    this.chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    const series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = this.value;
    series.dataFields.dateX = this.category;
    series.tooltipText = '{value}';

    series.tooltip.pointerOrientation = 'vertical';

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.snapToSeries = series;
    this.chart.cursor.xAxis = dateAxis;

    // Scrollbar
    // this.chart.scrollbarY = new am4core.Scrollbar();
    // this.chart.scrollbarX = new am4core.Scrollbar();

    // if (this.maxWidth) {
    //   const label = categoryAxis.renderer.labels.template;
    //   label.wrap = true;
    //   label.maxWidth = this.maxWidth;
    // }
  }

  dispose() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  setData(data) {
    const newData = data.map((item) => {
      const obj = {};
      const { category } = this;

      Object.defineProperty(obj, category, {
        value: item[category],
      });

      Object.defineProperty(obj, this.value, {
        value: toNumber(item[this.value]) ? item[this.value] : 0,
      });

      return obj;
    });
    this.chart.data = newData;
  }

  setTitle(val) {
    const title = this.chart.titles.create();

    title.text = val;
    title.fontSize = 25;
    title.marginBottom = 30;
  }
}
