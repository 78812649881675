<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <div>
        <main-menu />
      </div>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      color="indigo"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>Healthkeeper</v-toolbar-title>
      <v-spacer />
      <toolbar-avatar />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <snackbars />
  </v-app>
</template>

<script>
import Snackbars from '@/plugins/notifications/Snackbars.vue';
import ToolbarAvatar from '@/views/components/ToolbarAvatar.vue';
import MainMenu from '@/views/layouts/MainMenu.vue';

export default {
  name: 'App',

  components: {
    MainMenu,
    Snackbars,
    ToolbarAvatar,
  },

  data: () => ({
    drawer: null,
  }),

  created() {
  },
};
</script>

<style scoped lang="scss">
</style>
