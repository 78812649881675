<template>
  <v-card class="mb-5">
    <v-card-title>
      <v-icon>mdi-checkbox-marked-outline</v-icon>
      3. Дополнительный медицинский осмотр по программе «Управление здоровьем» и выявленные
      факторы риска основных социально-значимых заболеваний (сердечно-сосудистых заболеваний,
      сахарного диабета, метаболических нарушений, астмы и пр.)
    </v-card-title>

    <v-card-text>
      <report-filter
        v-model="filter"
        @input="load"
      />
    </v-card-text>

    <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:item.percent="{item}">
        <div v-if="item.percent">
          {{ item.percent }}%
        </div>
      </template>
    </v-data-table>
    <h2 class="text-center chart-title">
      Распространенность факторов риска развития наиболее заничимых заболеваний
    </h2>
    <div
      id="pie-2"
      class="chart-pie"
    />
    <div
      id="columnseries-33"
      class="chart-pie"
    />
  </v-card>
</template>

<script>
import throttle from 'lodash/throttle';
import { QueryHelper } from '@/services/query-helper';
import ReportFilter from '@/views/components/ReportFilter.vue';

import Pie from '@/services/charts/Pie';
import ColumnSeries from '@/services/charts/ColumnSeries';

export default {
  name: 'Report3',
  components: {
    ReportFilter,
  },

  data() {
    return {
      loading: false,
      options: {},
      search: '',
      items: [],
      filter: {
        date: [
          this.$date(new Date()).startOf('month').format('YYYY-MM-DD'),
          this.$date(new Date()).format('YYYY-MM-DD'),
        ],
      },
      chart: null,
      headers: [
        { text: 'Показатель', value: 'name' },
        { text: 'Количество человек', value: 'people_count', align: 'center' },
        { text: 'Доля5 (3 от 2), в  %', value: 'percent', align: 'center' },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  async mounted() {
    // Pie
    this.chart = new Pie('pie-2', [], 'people_count', 'name');
    this.chart.start();
    this.chart.legend();
    // Pie
    this.ColumnSeries = new ColumnSeries('columnseries-33', [], ['people_count'], 'name', 200);
    this.ColumnSeries.start();
    // this.ColumnSeries.legend();
  },

  destroyed() {
    this.chart.dispose();
    this.ColumnSeries.dispose();
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const params = {
          search: this.search,
          ...QueryHelper.tableSorting(this.options),
          ...QueryHelper.filter(this.filter),
        };

        const response = await this.$http.get('v1/reports/leader/report3', { params }).then((r) => r.data);

        if (response.success) {
          this.items = response.data;

          this.chart.setData(this.items);

          this.ColumnSeries.setData(this.items);
          this.ColumnSeries.setCountItemsView(3);
          this.ColumnSeries.responsive();
        }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped>
</style>
