import { Http } from '@/plugins/http';

/* eslint-disable import/prefer-default-export */
export class UserService {
  /**
   *
   * @param {number} id
   * @return Promise<CustomerProfile>
   */
  static getProfile(id) {
    return Http.get(`v1/customers/${id}`)
      .then((response) => response.data.data);
  }
}
