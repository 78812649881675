<template>
  <v-card class="mb-5">
    <v-card-title>
      <v-icon>mdi-checkbox-marked-outline</v-icon>
      5. Основной отчет о деятельности здравпункта на предприятии
    </v-card-title>

    <v-card-text>
      <report-filter
        v-model="filter"
        @input="load"
      />
    </v-card-text>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Наименование
            </th>
            <th class="text-center">
              Отчетные даты
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, idx) in items"
            :key="idx"
          >
            <td>{{ item.name }}</td>
            <td>
              <div>
                <table style="width: 100%;">
                  <tr>
                    <td
                      v-for="(period, subIdx) in item.periods"
                      :key="subIdx"
                      class="text-center"
                      style="width: 120px;"
                    >
                      {{ period.value }}
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import throttle from 'lodash/throttle';
import { QueryHelper } from '@/services/query-helper';
import ReportFilter from '@/views/components/ReportFilter';

export default {
  name: 'Report5',
  components: { ReportFilter },

  data() {
    return {
      loading: false,
      options: {},
      search: '',
      items: [],
      filter: {
        date: [
          this.$date(new Date()).startOf('month').format('YYYY-MM-DD'),
          this.$date(new Date()).format('YYYY-MM-DD'),
        ],
      },
      chart: null,
    };
  },

  watch: {
    // options: {
    //   handler() {
    //     this.load();
    //   },
    //   deep: true,
    // },
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const params = {
          search: this.search,
          // ...QueryHelper.tableSorting(this.options),
          ...QueryHelper.filter(this.filter),
        };

        const response = await this.$http.get('v1/reports/leader/report5', { params }).then((r) => r.data);

        if (response.success) {
          this.items = response.data;
        }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped>

</style>
