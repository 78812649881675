import Vue from 'vue';
import Vuelidate from 'vuelidate';

import './style.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { HttpPlugin } from './plugins/http';
import { DatePlugin } from './plugins/date';
import { NotificationPlugin } from './plugins/notifications/notification';
import '@/views/components/modals';

Vue.config.productionTip = false;

// Активация плагинов
Vue.use(HttpPlugin);
Vue.use(DatePlugin);
Vue.use(Vuelidate);
Vue.use(NotificationPlugin);

new Vue({
  router,
  store,
  vuetify,

  created() {
  },
  render: (h) => h(App),
}).$mount('#app');
