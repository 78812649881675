import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import toNumber from 'lodash/toNumber';

export default class Pie {
  constructor(id, data, value, category, legendId) {
    this.id = id;
    this.data = data;
    this.value = value;
    this.category = category;
    this.legendId = legendId;

    this.chart = am4core.create(this.id, am4charts.PieChart);
    this.pieSeries = this.chart.series.push(new am4charts.PieSeries());
  }

  start() {
    this.chart.width = am4core.percent(100);

    this.pieSeries.dataFields.value = this.value;
    this.pieSeries.dataFields.category = this.category;
  }

  legend() {
    // Add a legend
    this.chart.legend = new am4charts.Legend();
    this.pieSeries.labels.template.disabled = true;
  }

  setData(data) {
    const newData = data.map((item) => {
      const obj = {};
      const { category } = this;

      Object.defineProperty(obj, category, {
        value: item[category],
      });

      Object.defineProperty(obj, this.value, {
        value: toNumber(item[this.value]) ? item[this.value] : 0,
      });

      return obj;
    });

    this.chart.data = newData;
  }

  setTitle(val) {
    const title = this.chart.titles.create();
    title.text = val;
    title.fontSize = 25;
    title.marginBottom = 30;
  }
}
