<template>
  <v-card class="mb-5">
    <v-card-title>
      !15. Штатная численность и расстановка медицинского персонала
    </v-card-title>
    <v-card-text>TODO</v-card-text>
    <!--
    <v-card-text>
      <report-filter
        v-model="filter"
        @input="load"
      />
    </v-card-text>

    <div class="table-custom">
      <div class="table-row table-row-border">
        <div class="table-col">
          <div class="table-row">
            <div class="table-col table-col--padding table-col--padding-no-border">
              Наименование должности (специальности)
            </div>
          </div>
        </div>
        <div class="table-col">
          <div class="table-row">
            <div class="table-col table-col--padding table-col table-col--padding-no-border">
              Число штатных  должностей в здравпункте,  ед. (ставки)
            </div>
          </div>
          <div class="table-row table-row--spacebetween">
            <div class="table-col table-col--padding table-col--padding-no-border">
              штатных
            </div>
            <div class="table-col table-col--padding table-col--padding-no-border">
              занятых
            </div>
          </div>
        </div>
        <div class="table-col">
          <div class="table-row">
            <div class="table-col table-col--padding table-col--padding-no-border">
              Число физических лиц на занятых должностях, чел
            </div>
          </div>
          <div class="table-row">
            <div class="table-col table-col--padding table-col--padding-no-border">
              физлица
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(item, index) of items"
        :key="index"
        class="table-row table-row-border"
      >
        <div class="table-col">
          <div class="table-row">
            <div
              class="table-col table-col--padding  table-col--padding-no-border"
              v-html="item.name"
            />
          </div>
        </div>
        <div class="table-col">
          <div class="table-row table-row--spacebetween">
            <div
              class="table-col table-col--padding  table-col--padding-no-border"
              v-html="item.date_1"
            />
            <div
              class="table-col table-col--padding  table-col--padding-no-border"
              v-html="item.date_2"
            />
          </div>
        </div>
        <div class="table-col">
          <div class="table-row">
            <div
              class="table-col table-col--padding  table-col--padding-no-border"
              v-html="item.date_3"
            />
          </div>
        </div>
      </div>
    </div>
    -->
    <!-- <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    /> -->
  </v-card>
</template>

<script>
import throttle from 'lodash/throttle';
// import { QueryHelper } from '@/services/query-helper';
import { reportData15 } from './report-data';

export default {
  name: 'Report15',
  components: {
  },

  data() {
    return {
      loading: false,
      options: {},
      search: '',
      items: [],
      filter: {
        date: [
          this.$date(new Date()).startOf('month').format('YYYY-MM-DD'),
          this.$date(new Date()).format('YYYY-MM-DD'),
        ],
      },
      chart: null,
      headers: [
        { text: 'Наименование', value: 'name' },
        { text: 'Наличие (нет - 0, есть - 1)', value: 'date_1', align: 'center' },
        { text: 'Количество единиц ', value: 'date_2', align: 'center' },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  async mounted() {
    this.items = reportData15;
  },

  destroyed() {
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        this.items = reportData15;
        // const params = {
        //   search: this.search,
        //   ...QueryHelper.tableSorting(this.options),
        //   ...QueryHelper.filter(this.filter),
        // };

        // const response = await this.$http.get('v1/reports/leader/report4', { params }).then((r) => r.data);

        // if (response.success) {
        //   this.items = response.data;
        //   this.chart.responsive();
        // }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
</style>
