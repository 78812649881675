<template>
  <v-card class="mb-5">
    <v-card-title>
      <v-icon>mdi-checkbox-marked-outline</v-icon>
      2. Выявление патологических состояний сердечно-сосудистой системы (ПРМО и ПСМО)
    </v-card-title>

    <v-card-text>
      <report-filter
        v-model="filter"
        @input="load"
      />
    </v-card-text>

    <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:item.percent_cases_count="{item}">
        <div v-if="item.percent_cases_count">
          {{ item.percent_cases_count }}%
        </div>
      </template>
      <template v-slot:item.percent_employee_count="{item}">
        <div v-if="item.percent_employee_count">
          {{ item.percent_employee_count }}%
        </div>
      </template>
    </v-data-table>
    <h2 class="text-center chart-title">
      Выявление превышения показателей деятельности ССС при проведении ПРМО/ПСМО Ленинскрй РЭС за период
    </h2>
    <div id="columnseries" />
  </v-card>
</template>

<script>
import throttle from 'lodash/throttle';
import ColumnSeries from '@/services/charts/ColumnSeries';
import ReportFilter from '@/views/components/ReportFilter.vue';
import { QueryHelper } from '@/services/query-helper';

export default {
  name: 'Report2',
  components: {
    ReportFilter,
  },

  data() {
    return {
      loading: false,
      options: {},
      search: '',
      items: [],
      filter: {
        date: [
          this.$date(new Date()).startOf('month').format('YYYY-MM-DD'),
          this.$date(new Date()).format('YYYY-MM-DD'),
        ],
      },
      chart: null,
      headers: [
        { text: 'Наименование показателя', value: 'name' },
        { text: 'Количество случаев всего', value: 'cases_count', align: 'center' },
        { text: 'Доля случаев от общего числа исследований в %', value: 'percent_cases_count', align: 'center' },
        { text: 'Количество человек всего', value: 'employee_count', align: 'center' },
        { text: 'Доля от общего числа обследованных физлиц %', value: 'percent_employee_count', align: 'center' },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  async mounted() {
    this.chart = new ColumnSeries('columnseries', [], ['employee_count'], 'name');
    this.chart.start();
  },

  destroyed() {
    this.chart.dispose();
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const params = {
          search: this.search,
          ...QueryHelper.tableSorting(this.options),
          ...QueryHelper.filter(this.filter),
        };

        const response = await this.$http.get('v1/reports/leader/report2', { params }).then((r) => r.data);

        if (response.success) {
          this.items = response.data;
          this.chart.setData(this.items);
          // this.chart.responsive();
        }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped>
#columnseries {
  margin: 20px 0;
}
</style>
