import Vue from 'vue';
import VueRouter from 'vue-router';
// import EmptyRouterView from '@/views/layouts/EmptyRouterView.vue';
import Dashboard from '@/views/pages/dashboard/Dashboard.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    alias: '/',
    name: 'dashboard',
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: Dashboard,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
