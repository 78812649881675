import axios from 'axios';
import { API_URL } from '@/config';

const Http = axios.create();
Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Http.interceptors.request.use(
  (config) => {
    // Проверка URL для автоподстановки ссылки на API сервер
    if (config.url.startsWith('v1/') || config.url.startsWith('v2/')) {
      config.url = `${API_URL}/${config.url}`;
      console.log(`Make request: ${config.url}`);
    }

    // if (window.AppGlobalSettings && window.AppGlobalSettings.token !== '') {
    // config.headers.Authorization = 'Bearer ' + window.AppGlobalSettings.token;
    // }

    return config;
  },
  (error) => Promise.reject(error),
);

const HttpPlugin = {
  install(Vue) {
    Vue.prototype.$http = Http;
  },
};

export {
  Http,
  HttpPlugin,
};
