import addDefaultOption from '@/services/select-helper';
import { Http } from '@/plugins/http';

export default {
  namespaced: true,

  state: {
    positions: [],
    structures: [],
    sexOptions: [],
    ageOptions: [],
  },

  actions: {
    async load({ commit }) {
      const filters = await Http.get('v1/reports/leader/report-filter').then((r) => r.data);
      commit('SET_FILTERS', filters);
    },
  },

  mutations: {
    SET_FILTERS(state, filters) {
      state.positions = addDefaultOption(filters.positions);
      state.structures = addDefaultOption(filters.structures);
      state.sexOptions = addDefaultOption(filters.gender_list);
      state.ageOptions = addDefaultOption(filters.age_list);
    },
  },
};
