import { v4 as uuidv4 } from 'uuid';

export const NotificationStoreModule = {
  name: 'notification',
  namespaced: true,

  state: {
    id: '',
    show: true,
    text: '',
    color: '',
    timeout: '',
  },

  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.id = uuidv4();
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
  },
};
