import { UserService } from '@/services/api/user.service';

export default {
  namespaced: true,

  state: {
    id: null,
    profile: null,
    events: [],
  },

  actions: {
    loadProfile({ commit }, customerId) {
      UserService.getProfile(customerId).then((response) => {
        commit('SET_PROFILE', response);
      });
    },
  },

  mutations: {
    SET_ID(state, id) {
      state.id = +id;
    },

    SET_EVENTS(state, items) {
      state.events = items;
    },

    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
  },
};
